import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { DateTime, Zone } from 'luxon';

import { parseDateTime, ParseDateTimeInput } from './parse-date-time';

export const getZonedJSDate = (dateTime: DateTime): Date => {
  return utcToZonedTime(dateTime.toJSDate(), dateTime.zoneName);
};

export const getZonedDateTime = (date: Date, zone: Zone | string): DateTime => {
  return DateTime.fromJSDate(
    zonedTimeToUtc(date, typeof zone === 'string' ? zone : zone.name),
  );
};

export const formatCustom = (date: ParseDateTimeInput, format: string) => {
  return parseDateTime(date)?.toFormat(format, { locale: 'en' }) ?? '-';
};

export const formatDate = (date: ParseDateTimeInput) =>
  formatCustom(date, 'LLL dd, yyyy');

export const formatTime = (
  date: ParseDateTimeInput,
  options?: { format?: string; zone?: boolean },
) => {
  const format = [options?.format ?? 'h:mm a'];
  if (options?.zone) {
    format.push('(ZZZZ)');
  }
  return (
    parseDateTime(date)?.toFormat(format.join(' '), { locale: 'en' }) ?? '-'
  ).toLowerCase();
};

export const formatTimeRange = (
  date: [ParseDateTimeInput, ParseDateTimeInput],
) => `${formatTime(date[0])} - ${formatTime(date[1])}`;

export const formatDateTime = (date: ParseDateTimeInput) =>
  formatCustom(date, 'LLL dd, yyyy, h:mm a');
